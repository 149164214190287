@import 'react-responsive-modal/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    @apply h-full text-black;
    font-family: 'futura', sans-serif;
    font-size: 15px;
  }

  body {
    @apply box-border pb-40 bg-gray-50;
  }

  svg {
    @apply fill-current stroke-current;
  }

  * {
    @apply box-border;
  }

  *:disabled {
    @apply opacity-60;
  }
  *:disabled *:disabled {
    @apply opacity-100;
  }

  .box-content * {
    box-sizing: content-box;
  }

  .container-h-full {
    min-height: calc(100vh - 9rem);
  }

  .outline-gray-50 {
    outline-color: theme('colors.gray.800');
  }

  button:focus {
    @apply outline-none;
  }

  .btn {
    @apply cursor-pointer bg-caarlblue rounded-large text-base text-white px-5 py-2 font-bold;
  }

  .button {
    @apply p-4 rounded-md bg-grey-300 text-white font-bold text-lg cursor-pointer;
  }

  .button:disabled,
  .btn:disabled {
    @apply bg-grey-300 opacity-25 text-white;
  }

  .text-title {
    @apply font-bold text-lg leading-6 my-8;
  }

  .label-question {
    @apply font-bold text-base;
  }

  .text-normal {
    @apply text-grey-100 text-lg mt-1;
  }

  input[type='radio']:checked + label {
    @apply bg-caarlgreen text-caarlblue;
  }

  .radio-label {
    min-width: 120px;
    @apply inline-block button m-2 px-4 text-center;
  }

  .container-centered {
    @apply mx-auto max-w-lg pb-32 mt-6 px-5;
  }

  @screen md {
    .container-centered {
      @apply pb-12;
    }
  }

  .fieldIcon {
    @apply bg-transparent bg-no-repeat bg-center z-10 absolute w-8;
    content: '';
    left: 2px;
    top: 2px;
    height: calc(100% - 4px);
  }

  .menu-content {
    transform: translateY(10px);
    opacity: 0;
    transition: all 0.4s;
    pointer-events: none;
  }

  .menu-toggle {
    cursor: pointer;
  }

  .menu-toggle:hover + .menu-content,
  .menu-content:hover {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  header.auth {
    @apply bg-caarlgreen;
    transform: rotate(7deg);
  }
  header.auth > * {
    transform: rotate(-7deg);
  }

  /* For a bad IOS bug : https://github.com/twbs/bootstrap/issues/23307 */
  input[type='date'] {
    min-height: 2.5rem;
  }

  input::placeholder,
  textarea::placeholder {
    @apply text-grey-300;
  }

  .checkbox {
    @apply p-2 font-normal;
  }

  .form-input {
    @apply border border-grey-100 rounded-md bg-white px-3 py-2 mt-1;
  }

  .form-error {
    @apply text-red-500 font-normal text-sm mt-1;
  }
}

@layer utilities {
}

.react-responsive-modal-modal {
  @apply !py-12 !px-8;
}

.disabled,
*:disabled {
  @apply opacity-70 pointer-events-none;
}
.disabled .disabled,
*:disabled .disabled,
.disabled *:disabled,
*:disabled *:disabled {
  @apply opacity-100;
}

#chFrame h1 {
  @apply text-xl font-bold mb-4;
}
#chFrame h2 {
  @apply text-base font-bold mx-4;
}
#chFrame p {
  @apply my-2;
}
.force-white-fill-path svg {
  zoom: 0.3;
}
.force-white-fill-path path,
.force-white-fill-path rect,
.force-white-fill-path circle {
  fill: white !important;
  stroke: white !important;
}
