@font-face {
  font-family: 'futura';
  src: url('/fonts/futuralt-book-webfont.woff2') format('woff2'),
    url('/fonts/futuralt-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'futura';
  src: url('/fonts/futuralt-bold-webfont.woff2') format('woff2'),
    url('/fonts/futuralt-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
